import {
    t,
    Trans
} from "@lingui/macro";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import {
    Button,
    Grid,
    MenuItem,
    Select,
    Stack, Typography
} from "@mui/material";
import {
    ErrorResponse,
    MatesSelect,
    MatesSwitch,
    MatesTextField,
    RestResponse,
    withUseFormHook
} from "@sinossi/mates-react-library";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { OperationService } from "services/operation/operation.service";
import * as Yup from "yup";
import {
    MainConstants,
    Operation
} from "../../../pages/models";
import InfoDialog from "../../shared/info-dialog/info-dialog";
import {
    OperationFormProps,
    OperationFormState,
    WorkersListResponse
} from "./models";

const OperationSchema = Yup.object().shape({
    shortDescription: Yup.string().required(t({
        id: "of-description-required",
        message: "La descrizione sintetica è obbligatoria"
    }))
});

class OperationForm extends React.Component<OperationFormProps & UseFormReturn, OperationFormState> {

    constructor(props: any) {
        super(props);

        this.state = {
            insertedOperation: null,
            isLoading: true,
            officeWorkers: [],
            selectedWorker: null,
            isExecutionModalOpen: false,
            isValueModalOpen: false
        };
    }

    private submitForm = (values: any) => {

        let isExent = values.exent != null ? values.exent : false;

        let operation: Operation = {

            id: this.props.operation !== null ? this.props.operation.id : null,
            congruous: values.congruous != null ? values.congruous : false,
            description: values.description,
            extraordinary: values.extraordinary != null ? values.extraordinary : false,
            executionMode: (values.executionMode !== "" && !isExent) ? values.executionMode : null,
            frequency: (values.frequency !== "" && !isExent) ? values.frequency : null,
            operationValue: (values.operationValue !== "" && !isExent) ? values.operationValue : null,
            geoArea: (values.geoArea !== "" && !isExent) ? values.geoArea : null,
            moneyOrigin: (values.moneyOrigin !== "" && !isExent) ? values.moneyOrigin : null,
            moneyOriginNotes: (values.moneyOriginNotes !== "" && !isExent) ? values.moneyOriginNotes : null,
            managementCode: values.managementCode,
            exent: isExent,
            exemptionReason: (isExent && (values.exemptionReason !== "" || values.exent !== "")) ? values.exemptionReason : null,
            shortDescription: values.shortDescription,
            worker: { id: this.state.selectedWorker as any }
        };

        this.setState(() => ({ insertedOperation: operation }));
        this.props.onOperationSubmit(operation);
    };

    componentDidMount() {

        this.setState(() => ({ isLoading: true }));

        if (this.props.operation !== null) {
            this.props.reset(this.props.operation);
        }


        OperationService.getOfficeWorkers(this.getOfficeWorkersCallback)

    }



    private getOfficeWorkersCallback = (response: RestResponse<WorkersListResponse, ErrorResponse>) => {

        if (!response.hasError()) {
            this.setState(() => ({
                officeWorkers: response.data?.list,
                isLoading: false
            }));
        } else {
            //todo: handle
        }
    }

    private backForm = () => {
        this.props.back();
    };

    setValueModalVisibility = () => {
        this.setState(() => ({ isValueModalOpen: !this.state.isValueModalOpen }));
    };

    setExecutionModalVisibility = () => {
        this.setState(() => ({ isExecutionModalOpen: !this.state.isExecutionModalOpen }));
    };

    customIsValid = () => {

        console.log(this.state.selectedWorker)

        let exent = this.props.getValues("exent");
        if (exent && this.state.selectedWorker) {
            return true;
        }

        if (exent && !this.state.selectedWorker) {
            return false;
        }

        let frequency = this.props.getValues("frequency");
        if (frequency === undefined || frequency === "") {
            return false;
        }

        let executionMode = this.props.getValues("executionMode");
        if (executionMode === undefined || executionMode === "") {
            return false;
        }

        let operationValue = this.props.getValues("operationValue");
        if (operationValue === undefined || operationValue === "") {
            return false;
        }

        let geoArea = this.props.getValues("geoArea");
        if (geoArea === undefined || geoArea === "") {
            return false;
        }

        let moneyOrigin = this.props.getValues("moneyOrigin");
        if (moneyOrigin === undefined || moneyOrigin === "") {
            return false;
        }

        let moneyOriginNotes = this.props.getValues("moneyOriginNotes");

        if ((moneyOrigin === "BANK_LOAN" || moneyOrigin === "OTHER") && (moneyOriginNotes === "" || !moneyOriginNotes))
            return false

        if (this.state.selectedWorker === null) {
            return false;
        }

        return true;
    };

    render() {

        let currentLanguage = window.localStorage.getItem("selected_language") || "IT";

        return (<>
            {this.state.isExecutionModalOpen && <InfoDialog simpleText={t({
                id: "of-execution-mode-modal-info-text",
                message: "Lorem ipsum dolor sit amet"
            })} title={t({
                id: "of-execution-mode-modal-info-title",
                message: "Modalità d'esecuzione"
            })} closingButtonAction={this.setExecutionModalVisibility} />}

            {this.state.isValueModalOpen && <InfoDialog simpleText={t({
                id: "of-value-modal-info-text",
                message: "Lorem ipsum dolor sit amet"
            })} title={t({
                id: "cf-value-modal-info-title",
                message: "Valore"
            })} closingButtonAction={this.setValueModalVisibility} />}

            {!this.state.isLoading && <form onSubmit={this.props.handleSubmit(this.submitForm)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MatesTextField
                            name="shortDescription"
                            variant={"outlined"}
                            label={t({
                                id: "of-short-description",
                                message: "Descrizione Sintetica"
                            })}
                            control={this.props.control}
                            trigger={this.props.trigger}
                            formState={this.props.formState} />
                    </Grid>
                    <Grid item xs={12}>
                        <MatesTextField
                            name="description"
                            label={t({
                                id: "of-description",
                                message: "Descrizione"
                            })}
                            variant={"outlined"}
                            control={this.props.control}
                            trigger={this.props.trigger}
                            formState={this.props.formState} />
                    </Grid>
                    <Grid item xs={12}>
                        <MatesSwitch
                            name="exent"
                            label={t({
                                id: "of-exent",
                                message: "Operazione Non Soggetta"
                            })}
                            control={this.props.control}
                            formState={this.props.formState}
                            trigger={this.props.trigger} />
                    </Grid>
                    {this.props.control._formValues.exent && <> <Grid item xs={12}>
                        <MatesTextField
                            name="exemptionReason"
                            variant={"outlined"}
                            label={t({
                                id: "of-exemption-reason",
                                message: "Ragione dell'esenzione"
                            })}
                            control={this.props.control}
                            formState={this.props.formState}
                            trigger={this.props.trigger}>
                        </MatesTextField>
                    </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <Typography variant="body1">
                                <Trans id="operation-worker-label">
                                    Il professionista che seguirà la pratica è
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Select fullWidth onChange={(e: any) => this.setState(() => ({
                                selectedWorker: e.target.value
                            }))}>
                                {this.state.officeWorkers?.map((item) => (<MenuItem key={item.id} value={item.id}>
                                    {item.name} {item.surname} - {item.email}
                                </MenuItem>))}
                            </Select>
                        </Grid>
                    </>}
                    {!this.props.control._formValues.exent && <>
                        <Grid item xs={6}>
                            <MatesSwitch
                                name="extraordinary"
                                label={t({
                                    id: "of-extraordinary",
                                    message: "Operazione straordinaria"
                                })}
                                control={this.props.control}
                                formState={this.props.formState}
                                trigger={this.props.trigger} />
                        </Grid>
                        <Grid item xs={6}>
                            <MatesSwitch
                                name="congruous"
                                label={t({
                                    id: "of-congruous",
                                    message: "Operazione congrua"
                                })}
                                control={this.props.control}
                                formState={this.props.formState}
                                trigger={this.props.trigger} />
                        </Grid>
                        <Grid item xs={6}>
                            <MatesSelect
                                name="frequency"
                                variant={"outlined"}
                                label={t({
                                    id: "of-frequency",
                                    message: "Frequenza operazione"
                                })}
                                control={this.props.control}
                                formState={this.props.formState}
                                trigger={this.props.trigger}>
                                {MainConstants.OPERATION_FREQUENCY_LIST.map(
                                    (item) => (<option value={item.key}
                                        key={item.key}>{currentLanguage === "IT" ? item.label : item.labelEn}</option>))}
                            </MatesSelect>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction={"row"} spacing={1} style={{
                                display: "inline-flex",
                                alignItems: "center",
                                width: "100%"
                            }}>
                                <MatesSelect
                                    name="executionMode"
                                    variant={"outlined"}
                                    label={t({
                                        id: "of-execution-mode",
                                        message: "Modalità di svolgimento"
                                    })}
                                    control={this.props.control}
                                    formState={this.props.formState}
                                    trigger={this.props.trigger}>
                                    {MainConstants.OPERATION_EXECUTION_MODE_LIST.map(
                                        (item) => (<option value={item.key}
                                            key={item.key}>{currentLanguage === "IT" ? item.label : item.labelEn}</option>))}
                                </MatesSelect>
                                <InfoIcon
                                    sx={{
                                        color: "blue",
                                        cursor: "pointer"
                                    }}
                                    onClick={this.setExecutionModalVisibility}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction={"row"} spacing={1} style={{
                                display: "inline-flex",
                                alignItems: "center",
                                width: "100%"
                            }}>
                                <MatesSelect
                                    name="operationValue"
                                    label={t({
                                        id: "of-operation-value",
                                        message: "Valore operazione"
                                    })}
                                    variant={"outlined"}
                                    control={this.props.control}
                                    formState={this.props.formState}
                                    trigger={this.props.trigger}>
                                    {MainConstants.OPERATION_VALUE_LIST.map(
                                        (item) => (<option value={item.key}
                                            key={item.key}>{currentLanguage === "IT" ? item.label : item.labelEn}</option>))}
                                </MatesSelect>
                                <InfoIcon
                                    sx={{
                                        color: "blue",
                                        cursor: "pointer"
                                    }}
                                    onClick={this.setValueModalVisibility}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <MatesSelect
                                name="geoArea"
                                variant={"outlined"}
                                label={t({
                                    id: "of-geo-area",
                                    message: "Area geografica di esecuzione"
                                })}
                                control={this.props.control}
                                formState={this.props.formState}
                                trigger={this.props.trigger}>
                                {MainConstants.GEO_AREA.map(
                                    (item) => (<option value={item.key}
                                        key={item.key}>{currentLanguage === "IT" ? item.label : item.labelEn}</option>))}
                            </MatesSelect>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1}>
                                <MatesSelect
                                    name="moneyOrigin"
                                    variant={"outlined"}
                                    label={t({
                                        id: "money-origin",
                                        message: "Provenienza fondi"
                                    })}
                                    control={this.props.control}
                                    formState={this.props.formState}
                                    trigger={this.props.trigger}>
                                    {MainConstants.MONEY_ORIGIN.map(
                                        (item) => (<option value={item.key}
                                            key={item.key}>{currentLanguage === "IT" ? item.label : item.labelEn}</option>))}
                                </MatesSelect>
                                {this.props.getValues("moneyOrigin") === "BANK_LOAN" &&
                                    <Typography variant={"body1"} color={"error"}
                                        style={{ display: "flex", verticalAlign: "baseline" }}>
                                        <ErrorIcon sx={{ mr: 2 }} />
                                        <Trans id={"of-insert-notes-bank"}>
                                            Inserire la banca erogante nel campo "Note sull'origine dei fondi" prima di
                                            procedere
                                        </Trans>
                                    </Typography>}
                                {this.props.getValues("moneyOrigin") === "OTHER" &&
                                    <Typography variant={"body1"} color={"error"}
                                        style={{ display: "flex", verticalAlign: "baseline" }}>
                                        <ErrorIcon sx={{ mr: 2 }} />
                                        <Trans id={"of-insert-notes-other"}>
                                            Inserire l'origine dei fondi nel campo "Note sull'origine dei fondi"
                                        </Trans>
                                    </Typography>}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <MatesTextField
                                name="moneyOriginNotes"
                                label={t({
                                    id: "of-money-origin-notes",
                                    message: "Note sull'origine dei fondi"
                                })}
                                variant={"outlined"}
                                control={this.props.control}
                                trigger={this.props.trigger}
                                formState={this.props.formState} />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <Typography variant="body1">
                                <Trans id="operation-worker-label">
                                    Il professionista che seguirà la pratica è
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Select fullWidth onChange={(e: any) => this.setState(() => ({
                                selectedWorker: e.target.value
                            }))}>
                                {this.state.officeWorkers?.map((item) => (<MenuItem key={item.id} value={item.id}>
                                    {item.name} {item.surname} - {item.email}
                                </MenuItem>))}
                            </Select>
                        </Grid>
                    </>}
                    <Grid item xs={12}>
                        <MatesTextField
                            name="managementCode"
                            label={t({
                                id: "of-management-code-notes",
                                message: "Codice Gestionale"
                            })}
                            variant={"outlined"}
                            control={this.props.control}
                            trigger={this.props.trigger}
                            formState={this.props.formState} />
                    </Grid>
                    <Grid item xs={12} textAlign="right">
                        {(!this.props.update && !this.props.isCreateAnalysis) && <Button
                            color="primary"
                            variant="outlined"
                            sx={{ mr: 1 }}
                            onClick={this.backForm}>
                            <Trans id={"of-back"}>
                                Indietro
                            </Trans>
                        </Button>}
                        {!this.props.update && <Button
                            color="primary"
                            variant="contained"
                            disabled={this.props.formState.isSubmitting || !this.customIsValid() || !this.props.formState.isValid}
                            type="submit">
                            <Trans id={"of-ahead"}>
                                Avanti
                            </Trans>
                        </Button>}
                        {this.props.update && <Button
                            color="primary"
                            variant="contained"
                            disabled={this.props.formState.isSubmitting}
                            type="submit">
                            <Trans id={"of-update"}>
                                Aggiorna Informazioni
                            </Trans>
                        </Button>}
                    </Grid>
                </Grid>
            </form>} </>);
    }
}

export default withUseFormHook<{}>(OperationForm, OperationSchema, { geoArea: "ITALY" });
