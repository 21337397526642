import React from "react";

import {
	CustomerOtherDataFormProps,
	CustomerOtherDataFormState
} from "./models";

import {
	Button,
	Grid,
	TextField,
	Typography
} from "@mui/material";

import {
	MatesDatePicker,
	MatesTextField,
	withUseFormHook
} from "@sinossi/mates-react-library";

import {
	t,
	Trans
} from "@lingui/macro";
import { LegalNature } from "pages/models";
import { DocumentService } from "../../../services/document.service";

class CustomerOtherDataForm extends React.Component<CustomerOtherDataFormProps, CustomerOtherDataFormState> {
	constructor(props: any) {
		super(props);

		this.state = {
			isLoading: true
		};
	}

	componentDidMount() {

		if (this.props.customer !== null) {
			if (this.props.customer.additionalInfo !== null) {
				this.props.reset(this.props.customer);
			}
		}

		this.setState(() => ({ isLoading: false }));
	}

	private submitForm = () => {
	};

	private downloadDocument = (id: string) => {
		DocumentService.downloadDocument(id);
	};

	private parseDocumentType = (documentKey: string) => {
		switch (documentKey) {
			case "ID_CARD":
				return <Trans id={"codf-id-card"}> Carta di Identità</Trans>;
			case "CCIA_REPORT":
				return <Trans id={"codf-ccia-report"}> Visura Camerale </Trans>;
			case "OTHER":
				return <Trans id={"codf-other"}> Altro </Trans>;
			case "BANK_ACCOUNT_STATEMENT":
				return <Trans id={"codf-bank-account-statement"}> Estratto Conto </Trans>;
			case "DRIVING_LICENCE":
				return <Trans id={"codf-driving-licence"}> Patente </Trans>;
			case "DURC":
				return <Trans id={"codf-durc"}> DURC </Trans>;
			case "OTHER_IDENTIFICATION_METHOD":
				return <Trans id={"codf-other-id"}> Altro metodo di identificazione </Trans>;
			case "LAST_BALANCE":
				return <Trans id={"codf-last-balance"}> Ultimo bilancio </Trans>;
			case "OWNER_APPOINTMENT_VERBAL":
				return <Trans id={"codf-owner-appointment-act"}> Atto di nomina degli Amministratori </Trans>;
			case "COMPANY_CONSTITUTION_ACT":
				return <Trans id={"codf-company-constitution-act"}> Atto Costitutivo </Trans>;
			case "IDENTIFICATION_RESUME":
				return <Trans id={"codf-identification-resume"}> Report Identificazione (Firmato) </Trans>;
		}
	};

	render() {

		const isPhysical: boolean = this.props.customer!.legalNature === LegalNature.DI || this.props.customer!.legalNature === LegalNature.PF || this.props.customer!.legalNature === LegalNature.PP;

		return (<>
			{!this.state.isLoading && <form onSubmit={this.props.handleSubmit(this.submitForm)}>
				<Grid container spacing={2}>
					{!isPhysical && <Grid item xs={6}>
						<MatesTextField
							name="vatCode"
							variant={"outlined"}
							label={t({
								id: "codf-vat-code",
								message: "Partita IVA"
							})}
							control={this.props.control}
							formState={this.props.formState}
							trigger={this.props.trigger}
							disabled={true}
						/>
					</Grid>}
					<Grid item xs={6}>
						<MatesTextField
							name="additionalInfo.businessType"
							variant={"outlined"}
							label={t({
								id: "codf-activity-type",
								message: "Tipologia Attività"
							})}
							control={this.props.control}
							formState={this.props.formState}
							trigger={this.props.trigger}
							disabled={true}
						/>
					</Grid>
					<Grid item xs={6}>
						{!isPhysical && <MatesDatePicker
							name="additionalInfo.constitutionDate"
							variant={"outlined"}
							label={t({
								id: "codf-constitution-date",
								message: "Data di costituzione"
							})}
							control={this.props.control}
							formState={this.props.formState}
							trigger={this.props.trigger}
							disabled={true}
						/>}
						{isPhysical && <MatesDatePicker
							name="additionalInfo.birthDate"
							variant={"outlined"}
							label={t({
								id: "codf-birth-date",
								message: "Data di nascita"
							})}
							control={this.props.control}
							formState={this.props.formState}
							trigger={this.props.trigger}
							disabled={true}
						/>}
					</Grid>
					<Grid item xs={12}>
						<TextField
							name="address"
							value={this.props.customer?.additionalInfo.address}
							variant={"outlined"}
							label={isPhysical ? t({
								id: "codf-home-address",
								message: "Residenza"
							}) : t({
								id: "codf-address",
								message: "Sede legale"
							})}
							disabled={true}
							fullWidth={true}
						/>
					</Grid>
					{<Grid item xs={12}>
						<Typography sx={{ fontWeight: "bold" }}>
							<Trans id="customer.documents">
								Documenti
							</Trans>
						</Typography>
						<ul>
							{this.props.customer?.documentList?.map((document) => (
								<li key={document.id}> {this.parseDocumentType(document.type)} - {Math.round(
									document.size / 1024)} kB {document.uploadedByAnOfficeInternal &&
										<span> - <b> <Trans id={"codf-uploaded-by-worker"}> Caricato dal professionista </Trans></b> </span>}
									<Button onClick={() => {
										this.downloadDocument(document.id);
									}}> Download </Button></li>))}
							{this.props.customer?.documentList?.length === 0 && <> <i> <Trans id="codf-no-document">Nessun documento
								presente </Trans></i></>}
						</ul>
					</Grid>}
				</Grid>
			</form>}
		</>);
	}
}

export default withUseFormHook<{}>(CustomerOtherDataForm, {}, {});
